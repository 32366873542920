import "./index.scss";
import { useContext, useEffect } from "react";
import MPPerkList from "../MPPastPerks/MPPerkList";
import SelectPerksModule from "../MPSelectPerks/MPSelectPerksContainer";
import MPMilestoneContainer from "../MPMilestoneContainer";
import Nav from "../../MPPerksNavigation";
import { IGetPerksRequest } from "../../../types/perks";
import { ContentStateType } from "../../../state/InitialState";
import { ContentContext } from "../../../context/ContentContext";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import UserAccountContext from "../../../context/UserAccountContext";
import fetchPerksData from "../../../helpers/fetchPerksData";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import MPArrivingSoon from "./MPArrivingSoon";
import Spinner from "../../Spinner";

const MPPerksContainer = () => {
  const {
    accountState,
    accountState: { isLoadingToken },
  } = useContext(UserAccountContext);
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);

  const {
    perksDispatch,
    perksState: { isLoadingPerkData, perks },
  } = useContext(PerksContext);
  const isLoadingMobileContent = contentState.isLoadingMobileContent;

  const { MileagePlanNumber, asoaMiles } = accountState.MileagePlanDetails;
  const { Guid, Value } = accountState.Token;

  const { title } = contentState?.Content[3]?.perksLanding?.topNav || {};

  const isTestEnv = process.env.REACT_APP_RELEASE_ENV === "test" || process.env.REACT_APP_RELEASE_ENV === "dev";

  const getPerksData = async () => {
    const payload: IGetPerksRequest = {
      asoaMiles: asoaMiles,
      guid: Guid,
      mileagePlanNumber: MileagePlanNumber,
    };
    await fetchPerksData(payload, Value, perksDispatch);
  };

  useEffect(() => {
    getPerksData();
    perksDispatch({ type: ACTIONS.SET_SELECTED_PERK_BENEFITS, value: [] });
    perksDispatch({ type: ACTIONS.SET_SELECTED_MILESTONE_PERK, value: null });
  }, []);

  return isLoadingPerkData || isLoadingMobileContent || isLoadingToken ? (
    <div className="perks-loader-container">
      <Spinner />
    </div>
  ) : (
    <div className="overviewPerksContainer">
      <Nav title={title ?? "Perks"} returnToScreen="hub" />
      <div className="mPPerksDetails">
        <MPMilestoneContainer />
        {perks?.availableMilestonePerks?.length > 0 || perks?.pastPerksList?.length > 0 ? (
          <OptimizelyFeature feature="perkselectiontoggle">
            {(isEnabled, variables) =>
              (isEnabled && variables.openToAll) || isTestEnv ? (
                <div className="mPSelectedPerks">
                  <SelectPerksModule />
                  <MPPerkList />
                </div>
              ) : null
            }
          </OptimizelyFeature>
        ) : null}
        <MPArrivingSoon />
      </div>
    </div>
  );
};

export default MPPerksContainer;
