import { useContext, useEffect, useState } from "react";
import { ContentContext } from "../../../context/ContentContext";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import cxs from "cxs";
import { IFulfillmentPerkDetails, IPastPerk, IPerkBenefit, PerkFulfillmentState } from "../../../types/perks";
import { PerksScreens } from "../../../helpers/constants";
import RoundedButton from "../../RoundedButton/RoundedButton";
import Nav from "../../MPPerksNavigation";
import "./index.scss";

const MPPerksRedemption = () => {
    const { contentState } = useContext(ContentContext);
    const { perksState, perksDispatch } = useContext(PerksContext);
    const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [startDate, setStartDate] = useState('');
    const [dateIsInFuture, setDateIsInFuture] = useState<boolean>(false);
    const {perkFulfillmentStates} = perksState;
    const [fulfillmentStates, setFulfillmentStates] = useState<IFulfillmentPerkDetails[]>(perkFulfillmentStates);

    useEffect(() => {
      const today = new Date();
      const formattedDate = today.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });
      setStartDate(formattedDate);
      setSelectedDate(formattedDate);
    }, []);
    
    useEffect(() => {
        console.log("UseEffect")
        perksDispatch({
            type: ACTIONS.SET_REDEMPTION_STATE,
            value: fulfillmentStates,
        });
        console.log("After ", fulfillmentStates )
        
        const stateAdded = fulfillmentStates.find(state => 
          state.perkBenefitId == perksState.activeRedemption?.perkBenefitId 
          && state.fulfillmentState === PerkFulfillmentState.FulfilledActive);
        
        if (stateAdded)
        {
            perksDispatch({
                type: ACTIONS.SET_ACTIVE_SCREEN,
                value: {screenName: PerksScreens.PerksRedemptionConfirmation},
            });
            
        }
        
    }, [fulfillmentStates])
    
    useEffect(() => {
        if (dateIsInFuture)
            {
                setIsSelectDisabled(false);
                return;
            }
            else {
                setIsSelectDisabled(true);
            }
    }, [dateIsInFuture]);

    const {
        background,
        buttonBackgroundColor,
        buttonText,
        buttonTextColor,
        datePickerHint,
        info,
        subtitle,
        title
    } = contentState?.Content[8]?.perksTryRedeemTrial || {};;

    const {
        ctaButtonTitle,
        ctaDisabledBackgroundColor,
        ctaDisabledColor,
        ctaEnabledBackgroundColor,
        ctaEnabledColor,
    } = contentState?.Content[1]?.perkSelection || {};
    
    const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

    const styles = {
        container: cxs({
            background: `var(--${background}, #FBFFF6)`,
        }),
        header: cxs({ color: `var(--${background}, #151515)` }),
        subtitle: cxs({ color: `var(--${background}, #2e2e2e)` }),
        selectButton: cxs({
            borderColor: `var(--${buttonTextColor}, #ffffff)`,
        }),
        selectBtn: cxs({
            backgroundColor: isSelectDisabled
                ? `var(--${ctaDisabledBackgroundColor}, #d4d4d4)`
                : `var(--${buttonBackgroundColor}, #03314f)`,
            color: isSelectDisabled ? `var(--${ctaDisabledColor}, #ffffff)` : `var(--${ctaEnabledColor}, #ffffff)`,
        }),
    };
    
    const handleDateChange = (inputEvent : string) => {
        const startDate : Date = new Date(inputEvent);
        const currentDate = new Date();
        const normalizedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const normalizedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        
        const formattedDate = startDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
        
        setSelectedDate(formattedDate);
        setDateIsInFuture(normalizedStartDate >= normalizedCurrentDate);
    };

    const handleSelectButton = () => {
        

        console.log("Before ", fulfillmentStates );
        
        const updatedFulfillment = fulfillmentStates.map(state => {
              if (state.perkBenefitId == perksState.activeRedemption!.perkBenefitId)
                  return {...state, 
                      fulfillmentState: PerkFulfillmentState.FulfilledActive,
                      fulfillmentDate: selectedDate
              }
            return state
        });
        
        setFulfillmentStates(updatedFulfillment);
        
        //handle fulfillment  in confirmation page
    };

    const currentBenefit : IPastPerk | undefined = perksState.perks.pastPerksList.find(perk => perk.perkBenefitId == perksState.activeRedemption?.perkBenefitId)
    
    
    const selectionDate = new Date(currentBenefit!.selectionDate);
    const redeemByDate = new Date(selectionDate.getFullYear() + 1, selectionDate.getMonth(), selectionDate.getDate());

    return (
        <div className={`perksSelectionPage xm ${styles.container}`}>
            <Nav
                title={title ?? ""}
                returnToScreen={PerksScreens.Hub}
            />
            <div className="perksConfirmationPage__headerText">
                <p className="perk-page-selection-introduction">{subtitle}</p>
            </div>
            <div className="container">
                <auro-datepicker
                    value={selectedDate}
                    calendarStartDate={startDate}
                    calendarFocusDate={startDate}
                    onInput={(e: { target: { value: string; }; }) => handleDateChange(e.target.value)}
                >
                    <span slot="fromLabel">{datePickerHint}</span>
                    <span slot="mobileDateLabel">{datePickerHint}</span>
                </auro-datepicker>
                <p className={`perksRedemptionConfirmationPage__subtitle margin-top-1 ${styles.subtitle}`}>{(info && currentBenefit?.selectionDate)?
                  info + " " + redeemByDate.toLocaleDateString() + "." :
                  "" }</p>


            </div>
            <div className="container">
            <RoundedButton
                className={`${isSelectDisabled ? "disabled" : ""} ${styles.selectBtn}`}
                label={buttonText ?? "Select"}
                disabled={isSelectDisabled}
                onClick={handleSelectButton}
                type="button"
                testId="select-button"
            />
            </div>
        </div>

    );
};

export default MPPerksRedemption;