import {getIcon} from "../../../helpers/getIcon";
import PerksTile from "../MPPerksTile";
import RoundedButton from "../../RoundedButton/RoundedButton";
import {useContext, useState} from "react";
import UserAccountContext from "../../../context/UserAccountContext";
import {ContentContext} from "../../../context/ContentContext";
import {ACTIONS, PerksContext} from "../../../context/PerksContext";
import cxs from "cxs";
import {IEnrollPerksRequest, IFulfillmentPerkDetails, IPerkBenefit} from "../../../types/perks";
import {PerksFulfillmentService, PerksFulfillmentRequest} from "../../../hooks/PerksFulfillmentService"
import {PerksScreens} from "../../../helpers/constants";
import {ContentStateType} from "../../../state/InitialState";
import "./index.scss";

const MPPerksRedemptionConfirmation = () => {
    //page stuff

    const { accountState } = useContext(UserAccountContext);
    const { contentState } = useContext(ContentContext);
    const { perksState, perksDispatch } = useContext(PerksContext);
    const {perkFulfillmentStates, perks} = perksState;
    const [fulfillmentStates, setFulfillmentStates] = useState<IFulfillmentPerkDetails[]>(perkFulfillmentStates);
    const { selectedPerkBenefits, selectedMilestonePerk } = perksState;

    const {
        backgroundColor,
        titleColor,
        title,
        subtitle,
        subtitleColor,
        cancelButtonTitle,
        confirmButtonTitle,
        cancelButtonBackgroundColor,
        confirmButtonBackgroundColor,
    } = contentState?.Content[1]?.perkSelection?.confirmSelection || {};

    const contentPerks: any = contentState?.Content[4]?.perks || {};

    const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

    const getSubtitle = (perksId: number, perkContentLst: any) => {
        var perkItem = perkContentLst?.find((item: any) => item.id === perksId);
        return perkItem?.perkSelectionTileSubtitle;
      };

    const styles = {
      container: cxs({
        background: `var(--${backgroundColor}, #fff)`,
      }),
      header: cxs({ color: `var(--${titleColor}, #151515)` }),
      subtitle: cxs({ color: `var(--${subtitleColor}, #2e2e2e)` }),
      confirmButton: cxs({
        color: `#fff`,
        backgroundColor: `var(--${confirmButtonBackgroundColor}, #03314f)`,
      }),
      cancelButton: cxs({
        borderColor: `var(--${cancelButtonBackgroundColor}, #ffffff)`,
      }),
    };

    const handleConfirm = async () => {
        
        const perkFulfillment = fulfillmentStates.find(fulfillment => fulfillment.perkBenefitId ===  perksState.activeRedemption!.perkBenefitId);
        const perk = perksState.perks.pastPerksList.find(perk => perk.perkBenefitId == perksState.activeRedemption!.perkBenefitId);
        
        console.log("fulfillment", perkFulfillment)
        console.log("perk", perk)
        
        if(!perk || !perkFulfillment || !perkFulfillment.fulfillmentDate)
        {
            return;
        }
        
        
        const request : PerksFulfillmentRequest = 
            {
                enrollPerkId: perk.perkBenefitId,
                milestoneId: perk.milestoneId,
                firstName: accountState.MileagePlanDetails.FirstName,
                lastName: accountState.MileagePlanDetails.LastName,
                mpNumber: accountState.MileagePlanDetails.MileagePlanNumber,
                emailAddress: accountState.MileagePlanDetails.Email,
                accountGuid: accountState.Token.Guid,
                fulfillmentDate: new Date(perkFulfillment.fulfillmentDate).toISOString(),
                nominee: {
                    firstName : "",
                    lastName : "",
                    mpNumber : "",
                    emailAddress : ""
                }
            };
     
        const res = await PerksFulfillmentService(request, accountState.Token.Value);
      
        perksDispatch({
            type: ACTIONS.SET_ACTIVE_SCREEN,
            value: {screenName: PerksScreens.PerksSelectionSuccess},
        });
             
    };

    const handleCancel = () => {
        perksDispatch({
            type: ACTIONS.SET_ACTIVE_SCREEN,
            value: {screenName: PerksScreens.Hub},
        });
    };

    const icon = getIcon(perksState.activeRedemption!.perkBenefitId, list, defaultCategory, defaultIconName);
    
    const currentFulfillment = perksState.perkFulfillmentStates.find(perk => perk.perkBenefitId == perksState.activeRedemption?.perkBenefitId);
    const currentPerk = perksState.perks.pastPerksList.find(perk => perk.perkBenefitId == perksState.activeRedemption?.perkBenefitId);
    
    const trialStartDateString = currentFulfillment!.fulfillmentDate;
    
    const trialStartDate = trialStartDateString? new Date(trialStartDateString) : null;
    const trialEndDate = new Date(trialStartDate!);
    trialEndDate!.setDate(trialStartDate!.getDate() + 14);
    
    return (
        <div className={`perksConfirmationPage__container ${styles.container}`}>
            <div className="perksConfirmationPage__headerText">
                <h2 className={`perksConfirmationPage__title ${styles.header}`}>{title}</h2>
                <p className={`perksConfirmationPage__subtitle ${styles.subtitle}`}>{subtitle}</p>
            </div>
            <div className="perksConfirmationPage__tilesContainer">
                <PerksTile
                    id={currentPerk!.perkBenefitId}
                    key={currentPerk!.perkBenefitId}
                    selected={true}
                    description={currentPerk!.perkDescription}
                    perkSelectionTileSubtitle={trialStartDate?.toLocaleDateString() + " - " + trialEndDate?.toLocaleDateString()}   
                    icon={icon.name}
                    category={icon.category}
                    handleClick={() => {}}
                    variant="outline"
                    isButton={false}
                    selectedPerks={selectedPerkBenefits}
                />
            </div>
            <div className="perksConfirmationPage__buttons">
                <RoundedButton
                    className={styles.confirmButton}
                    label={confirmButtonTitle ?? "Confirm"}
                    type="button"
                    onClick={handleConfirm}
                />
                <RoundedButton
                    className={styles.cancelButton}
                    label={cancelButtonTitle ?? "Choose a different perk"}
                    type="button"
                    outline
                    onClick={handleCancel}
                />
            </div>
        </div>
    );
}

export default MPPerksRedemptionConfirmation;