import React, {useContext, useEffect, useState} from 'react'

import Nav from "../MPPerksNavigation";
import {ACTIONS, PerksContext} from '../../context/PerksContext';
import {ContentStateType} from '../../state/InitialState';
import {ContentContext} from "../../context/ContentContext";
import RoundedButton from '../RoundedButton/RoundedButton';
import cxs from 'cxs';
import "./index.scss";
import {PerksScreens} from '../../helpers/constants';
import {IFulfillmentPerkDetails, PerkFulfillmentState} from '../../types/perks';
import UserAccountContext from '../../context/UserAccountContext';
import ContentViewer from "../ContentViewer";

const MPPerksRedemptionInfo = () => {
  const { accountState } = useContext(UserAccountContext);
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { perksState, perksDispatch } = useContext(PerksContext);

  const { perks, perkFulfillmentStates, activeRedemption, activeScreen } = perksState;
  const { MileagePlanNumber, asoaMiles } = accountState.MileagePlanDetails;
  const { Guid, Value } = accountState.Token;
  const currentPerkId = activeRedemption?.perkBenefitId;
  const fulfillmentDetails : IFulfillmentPerkDetails | undefined = perkFulfillmentStates.find(fulfillmentPerk => fulfillmentPerk.perkBenefitId === currentPerkId);

  useEffect(() => {
    if (fulfillmentDetails?.fulfillmentDate)
    {
      const trialStartDate =  new Date(fulfillmentDetails?.fulfillmentDate)
      const trialEndDate = new Date(trialStartDate !.getFullYear(), trialStartDate !.getMonth(), trialStartDate !.getDate()+14);
      setDateRange({startDate: trialStartDate, endDate: trialEndDate});
    }
  }, []);
  type TrialDateRange = {
    startDate : Date,
    endDate : Date,
  }
  const [dateRange, setDateRange] = useState<TrialDateRange>();
  
  const { 
    title,
    backgroundColor,
    buttonText,
    buttonTextColor,
    buttonBackgroundColor,
   }  = contentState?.Content[6]?.perksLadder || {};

  const { backIconColor} =
  contentState?.Content[3]?.perksLanding?.topNav || {};

  const perkId = fulfillmentDetails?.perkBenefitId;

  const contentPerks: any =  contentState?.Content[4]?.perks?.find(
    (perk) => perk.id === perkId
  ) || {};

  const styles= {
    container: cxs({
      backgroundColor: `var(--${backgroundColor}, #FBFFF6)`,
    }),
    backButton: cxs({
      color: `var(--${buttonTextColor}, #FFFFFF)`,
      backgroundColor: `var(--${buttonBackgroundColor}, #03314F)`,
    }),
   }
   
   const dateDisplay = () => {
     return fulfillmentDetails?.fulfillmentState == PerkFulfillmentState.ExpiredBeforeRedemption? 
         "" :
     dateRange?.startDate.toLocaleDateString() + " - " + dateRange?.endDate.toLocaleDateString()
   }
   
  const handleGoToHub = async () => {
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName: PerksScreens.Hub },
    });
  };
  
  return (
  <div className={`perksRedemptionInfo ${styles.container}`} data-testid="perks-redemption-info-container">
    <Nav 
      title={contentPerks.perkName} 
      returnToScreen={PerksScreens.Hub}
      customBackgroundColor={backgroundColor}
    />
    <div className='container'>
      <h5>{activeScreen?.title}</h5>
      <p>
      {
        dateDisplay()
      }
      </p>
      <ContentViewer content={activeScreen?.data} />
    </div>
    <div className='perks_ladder_back_button'>
      <RoundedButton
          label={"Back to Alaska perks"}
          className={styles.backButton}
          onClick={handleGoToHub}
          type="button"
          testId="ladder-back-button"
        />
    </div>
  </div>
  )
}

export default MPPerksRedemptionInfo