import { appInsights } from "../AppInsights";

const queryHeaderFooter = async (endpoint = "", responseType) => {
  try {
    const result = await fetch(
      `${process.env.REACT_APP_HEADERFOOTER_ENDPOINT}${endpoint}`,
      {
        headers: {
          accepts: responseType,
          "Access-Control-Max-Age": 86400,
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_HEADERFOOTER_APIM_KEY
        }
      }
    ).then(response => {
      if (!response.ok) throw new Error(`${endpoint} fetch failed.`);
      return responseType === "application/json"
        ? response.json()
        : response.text();
    });
    return result;
  } catch (error) {
    appInsights?.trackException({ exception: `${error} Failed to retrieve headerFooter.` });
    return { error };
  }
};

const addFooterJS = async script => {
  const scriptElement = document.createElement("script");
  scriptElement.setAttribute("defer", 1);
  scriptElement.setAttribute("async", 1);
  scriptElement.innerText = script;
  document.body.appendChild(scriptElement);
};

const addHeaderCSS = async styles => {
  const styleElement = document.createElement("style");
  styleElement.innerText = styles;
  document.head.appendChild(styleElement);
};

const logError = error => {
  return false;
};
const AppUserAgents = {
  Android: "ALKApp/Android",
  iOS: "ALKApp/iOS",
  Header: "NativeHeader"
};

export function isFromMobileWebView() {
  return (
    window.navigator.userAgent.includes(AppUserAgents.Android) || window.navigator.userAgent.includes(AppUserAgents.iOS)
  );
}

export function isNativeHeader() {
  return (
    window.navigator.userAgent.includes(AppUserAgents.Header)
  );
}

export const mountHeaderFooter = async (headerMountPoint, footerMountPoint) => {
  if (!isFromMobileWebView()) {
    const htmlPromise = queryHeaderFooter(
      `headerfooterhtml?var=public&tiq=false`,
      "application/json"
    );
    const jsPromise = queryHeaderFooter("footer.js", "text/javascript");
    const cssPromise = queryHeaderFooter("header.css", "text/css");
    // load CSS first to avoid flash of unstyled HeaderFooter
    const css = await cssPromise;
    if (css.error) {
      return logError(css.error);
    }
    addHeaderCSS(css);
    const htmlJson = await htmlPromise;
    if (htmlJson.error) {
      return logError(htmlJson.error);
    }
    headerMountPoint.innerHTML = htmlJson.header;
    footerMountPoint.innerHTML = htmlJson.footer;
    // load CSS last to avoid querying unmounted Dom elements;
    const js = await jsPromise;
    if (js.error) {
      return logError(js.error);
    }
    addFooterJS(js.replaceAll(".remove()", "?.remove()")); // render null value innocuous
  }
  return true;
};

export default mountHeaderFooter;